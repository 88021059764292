<template>
  <div>
    <div class="d-flex justify-content-start ms-8 mt-4">
      <h2>
        <span style="color: #14993c"> Español </span>
      </h2>
    </div>
    <section>
      <div class="container row d-flex flex-column aboutUs py-0">
        <div class="__content d-flex py-0">
          <img
            class="__curve"
            src="../../assets/icons/curva.svg"
            alt="green-curve"
          />
          <div class="__card_row" v-for="card in cardsES" :key="card.id">
            <div class="___about_card shadow form wet-form mt-5 mb-0">
              <img
                :src="require(`../../assets/${card.img}`)"
                alt="aboutUs-card-img"
              />
              <div>
                <h3>
                  <input
                    class="form-control form-title"
                    type="text"
                    v-model="card.title"
                  />
                </h3>
                <textarea
                  rows="4"
                  class="form-control"
                  type="text"
                  v-model="card.text"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="d-flex justify-content-start ms-8 mt-4 mb-5">
      <button class="btn btn-success btn-lg mt-3" @click="syncContent('ES')">
        Guardar
      </button>
    </div>

    <hr class="pill" />

    <div class="d-flex justify-content-start ms-8 mt-5">
      <h2>
        <span style="color: #14993c"> English </span>
      </h2>
    </div>
    <section>
      <div class="container row d-flex flex-column aboutUs py-0">
        <div class="__content py-0">
          <img
            class="__curve"
            src="../../assets/icons/curva.svg"
            alt="green-curve"
          />
          <div class="__card_row" v-for="card in cardsEN" :key="card.id">
            <div class="___about_card shadow form wet-form mt-5 mb-0">
              <img
                :src="require(`../../assets/${card.img}`)"
                alt="aboutUs-card-img"
              />
              <div>
                <h3>
                  <input
                    class="form-control form-title"
                    type="text"
                    v-model="card.title"
                  />
                </h3>
                <textarea
                  class="form-control"
                  rows="4"
                  type="text"
                  v-model="card.text"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="d-flex justify-content-start ms-8 mt-4 mb-5">
      <button class="btn btn-success btn-lg mt-3" @click="syncContent('EN')">
        Save
      </button>
    </div>
  </div>
</template>

<script>
import { getSection, putSection } from "../../helpers/contenido";
import store from "../../store";

export default {
  name: "QuienesSomos",
  data() {
    return {
      cardsES: [],
      carsEN: [],
    };
  },
  mounted() {
    this.fetchData();
    this.fetchContent();
  },
  watch: {
    "$store.state.sections": function () {
      this.fetchData();
    },
  },
  methods: {
    fetchData() {
      if (store.state.sections.aboutUs) {
        this.data = { ...store.state.sections.aboutUs.components };
      }
    },
    async fetchContent() {
      const section = await getSection("aboutUs");
      const sectionEs = section.filter((section) => section.language == "ES");
      const sectionEn = section.filter((section) => section.language == "EN");
      this.sectionEs = sectionEs;
      this.sectionEn = sectionEn;
      this.cardsES = sectionEs[0].components.cards;
      this.cardsEN = sectionEn[0].components.cards;
    },
    async syncContent(lan) {
      if (lan === "ES") {
        const words = {
          id: this.sectionEs[0]._id,
          section: "aboutUs",
          components: {
            cards: this.cardsES,
          },
          language: "ES",
        };
        await putSection(words);
      } else {
        const words = {
          id: this.sectionEn[0]._id,
          section: "aboutUs",
          components: {
            cards: this.cardsEN,
          },
          language: "EN",
        };
        await putSection(words);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@use "src/assets/scss/colors";
@use "src/assets/scss/typography";

.vertical-background {
  left: 30%;
  z-index: -1;
  height: 100%;
}

.aboutUs {
  background-image: url("../../assets/img/x-horizontal-wave.svg");
  background-size: contain;
  display: flex;
  justify-content: center;
  text-align: center;
  min-height: 50vh;
  width: 100%;
  position: relative;
  margin: auto;

  .__curve {
    height: 90%;
    width: 50%;
    position: absolute;
  }

  .__content {
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: 1366px;
    width: 100%;
    padding: 20px;
    position: relative;
  }

  .__card_row {
    display: flex;
    width: 100%;

    .___about_card {
      align-items: center;
      background-color: white;
      border-radius: 18px;
      display: flex;
      text-align: left;
      width: 100%;
      margin: 0 0 10%;
      max-height: 208px;
      position: relative;
      padding: 20px;
    }

    .___about_card img {
      aspect-ratio: 1;
      width: 90px;
      max-width: 140px;
      margin: 0 15px 0 0;
    }
  }

  h3 {
    color: colors.$wet-green;
  }

  p {
    margin: 0;
  }
}
.form-title {
  color: #14993c;
}
@media (min-width: 1200px) {
  .aboutUs {
    display: inline-flex;
    justify-content: center;
    text-align: center;
    padding: 120px 0;
    min-height: 50vh;
    width: 100%;

    .__content {
      max-width: 1366px;
      width: 100%;
      padding: 65px 0;
    }

    .__card_row {
      justify-content: center;

      .___about_card {
        align-items: normal;
        flex-direction: column;
        height: 298px;
        justify-content: center;
        width: 50%;
        margin: 0 25% 8% 0;
        padding: 0 5% 0 10%;
      }

      .___about_card img {
        position: absolute;
        width: auto;
        left: -40px;
        margin: 0;
      }

      .___about_card p {
      }

      .___about_card p {
      }

      &:nth-child(odd) .___about_card {
        margin: 0 0 10% 25%;
      }

      &:nth-child(odd) .___about_card img {
        top: -20px;
      }

      &:nth-child(3n + 1) .___about_card img {
        bottom: -20px;
        top: auto;
      }
    }
  }
}

hr.pill {
  height: 0px;
  margin: auto auto;
  border-radius: 2px;
  color: #14993c !important;
  border: 2px solid currentColor;
  width: 80%;
}

.ms-8 {
  margin-left: 8rem;
}
</style>
